import { SocialHead } from "@components/all-pages/SocialHead";
import { LoadRoobert } from "@lux/atoms/components/LoadRoobert";
import { ProtocolBackgroundVideo } from "@components/protocol-landing/ProtocolBackgroundVideo";
import { ExternalLink } from "@lux/atoms/components/ExternalLink";
import { LuxButton } from "@lux/atoms/components/LuxButton";
import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import type { NextPage } from "next";

const Content = () => {
  return (
    <div className="content flex-center flex-column spread">
      <div className="inner flex-center flex-column text-center">
        <h2>A mysterious light appears...</h2>

        <LuxButton
          label="Claim Early Access NFT"
          rounded
          size="large"
          variant="outline"
          className="claim-button"
          disabled={true}
        />
        <p className="limited text-tertiary fs-xs mt-3">
          All spots taken for now. Check back later.
        </p>
      </div>

      <div className="flex-center flex-column my-4">
        <p className="mb-1 px-3 install-notice text-secondary">
          Make sure you have Glow installed and wallet set up.
        </p>
        <ExternalLink className="download" href="https://glow.app/download">
          Need to download Glow?
        </ExternalLink>
      </div>

      <style jsx>{`
        .content {
          position: relative;
          left: 0;
          top: 45%;
          width: 100%;
          height: 55%;

          .inner {
            margin-top: 60px;
            height: 100%;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              margin-top: 40px;
            }

            h2 {
              font-size: 2.5rem;
              margin-top: 1rem;
              margin-bottom: 3rem;
              line-height: 1.2;

              @media (max-width: ${ResponsiveBreakpoint.medium}) {
                padding: 0 3rem;
              }

              @media (max-width: ${ResponsiveBreakpoint.small}) {
                font-size: 2.25rem;
              }

              @media (max-width: ${ResponsiveBreakpoint.tiny}) {
                padding: 0 1rem;
                margin: 0.5rem 0 1.5rem 0;
              }
            }

            .reserved {
              margin-top: -1.5rem;
              margin-bottom: 1.5rem;

              @media (max-width: ${ResponsiveBreakpoint.tiny}) {
                margin-top: 0;
              }
            }

            .limited {
              font-weight: 500;
              letter-spacing: 0.5px;
            }
          }

          :global(.claim-button) {
            min-width: 260px;
          }

          .install-notice {
            font-size: var(--font-size-sm);
            text-align: center;
          }
        }
      `}</style>
    </div>
  );
};

const Index: NextPage = () => {
  return (
    <div className="protocol">
      <SocialHead
        title="Glow"
        subtitle="A Mysterious Light"
        description="Reserve your spot in the bright future."
        imageUrl="https://cdn.lu.ma/static-social/coming-social.jpg"
        canonicalPath="/protocol"
      />
      <LoadRoobert />
      <ProtocolBackgroundVideo />
      <Content />

      <style jsx>{`
        :global(body.theme-root.light),
        :global(body.theme-root.dark) {
          --primary-bg-color: #121212;
        }

        :global(#__next, .lux-modal-root) {
          height: 100%;
          background-color: var(--primary-bg-color);
        }

        .protocol {
          position: relative;
          font-family: "Roobert";
          height: 100%;
          min-height: 650px;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            min-height: 500px;
          }
        }
      `}</style>
    </div>
  );
};

export default Index;
