export const REM_PX = 16;

export const pxToRem = (pixels: number): string => {
  return `${(pixels / REM_PX).toFixed(4)}rem`;
};

export const pxToRemNum = (pixels: number): number => {
  return pixels / REM_PX;
};

export const clientSidePxPerRem = (): number => {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
};

// ATTENTION: If you change these values, make sure you update the breakpoints
// in .scss files too.
export enum ResponsiveBreakpoint {
  never = "0px",
  tiny = "450px",
  tinyPlus1 = "451px",
  small = "650px",
  smallPlus1 = "651px",
  medium = "820px",
  mediumPlus1 = "821px",
  large = "1000px",
  largePlus1 = "1001px",
  always = "99999px",
}

export enum ResponsiveBreakpointNumbers {
  tiny = 450,
  small = 650,
  medium = 820,
  large = 1000,
}

export type MobileBreakpointOption =
  | "always"
  | "tiny"
  | "small"
  | "medium"
  | "large"
  | "never";

export const isMobile = ({
  width,
  breakpoint,
}: {
  width?: number;
  breakpoint: MobileBreakpointOption;
}): boolean => {
  if (breakpoint === "always") {
    return true;
  }
  if (breakpoint === "never") {
    return false;
  }
  if (width == null) {
    return false;
  }
  return width <= ResponsiveBreakpointNumbers[breakpoint];
};
